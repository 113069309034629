<template>
  <!-- <van-nav-bar title="关于" left-arrow @click-left="onClickLeft"></van-nav-bar> -->

  <van-cell-group inset style="margin-top: 40px">
    <van-cell
      title="关于烟台市智慧在线收缴费平台"
      size="large"
      label="本平台由坤云(烟台)数字科技有限公司提供相关服务,
    在使用过程遇到任何问题请联系我们,我们的客服热线是:400-6622205 服务时间:(工作日) 8:30 - 17:30"
    />
  </van-cell-group>
</template>

<script>
import { Cell, CellGroup } from "vant";
import { onMounted } from "@vue/runtime-core";
export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  setup() {
    onMounted(() => {
      /* eslint-disable */
      // iCityBridge.setTitle("关于");
    });
  },
};
</script>

<style lang="less"></style>
